import Utils from "../utils";

export default class Search {
    private backdrop: HTMLElement;
    private select: any;
    constructor() {
        this.backdrop = document.querySelector(".content-backdrop") as HTMLElement;

        this.setupSelect();
    }

    setupSelect() {
        this.select = jQuery("#header_search").select2({
            // @ts-ignore
            dropdownParent: document.querySelector("#layout-navbar"),
            placeholder: "Search...",
            ajax: {
                url: "/api/v1/search",
                dataType: 'json',
                delay: 250,
                // @ts-ignore
                data: (params: { term: any; page: any; }) => {
                    return {
                        q: params.term, // search term
                        page: params.page
                    };
                },
                // @ts-ignore
                processResults: (data: any, params: any) => {
                    const s2Format = {results: []}
                        Object.keys(data.data).forEach((model: any) => {
                            const values = data.data[model]
                            let mappedValues = values.map((v: any) => {
                                let id = v.id;
                                let text = v.name;
                                if (!id) {
                                    id = v.uuid;
                                }
                                if (!text) {
                                    text = v.title;
                                }
                                return {id: id, text: text, model: model}
                            });
                            if (mappedValues.length > 0) {
                                // @ts-ignore
                                s2Format.results.push({
                                    "text": Utils.translate(`search.${model}`),
                                    "children": mappedValues
                                })
                            }
                        })
                    return s2Format;
                },
                cache: true
            },
            escapeMarkup: (markup: any) => { return markup; }, // let our custom formatter work
            minimumInputLength: 1,
        });
        this.select.on("select2:opening", (e: any) => {
            this.backdrop.classList.add("show");
        });
        this.select.on("select2:closing", (e: any) => {
            this.backdrop.classList.remove("show");
        });
        this.select.on("select2:select", (e: any) => {
            const data = e.params.data;
        })
    }
}
